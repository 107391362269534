import fileIcon from '../images/file.svg';
import trashIcon from '../images/trash.svg';

function FileList({ files, removeFile }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Size</th>
          <th>Type</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {files.map((f, i) => {
          return (
            <tr key={`file-${i}`}>
              <td>
                <img src={fileIcon} alt="file" /> {f.name}
              </td>
              <td>{Math.round(f.size * 0.001)} KB</td>
              <td>{f.type}</td>
              <td>
                <a onClick={() => removeFile(i)}>
                  <img src={trashIcon} alt="delete" />
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default FileList;
