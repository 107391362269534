import React, {useMemo, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 25,
  backgroundColor: '#2bb673',
  color: '#FAF7E8',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  width: 200,
};

function StyledDropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    props.onDrop(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const style = useMemo(() => ({
    ...baseStyle
  }), []);

  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p style={{marginTop: 9, marginBottom: 9}}>
          Click here to add files
        </p>
      </div>
    </div>
  );
}

export default StyledDropzone;
