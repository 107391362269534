import { useEffect, useState } from 'react';
import './App.scss';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

import StyledDropzone from './components/StyledDropzone';
import FileList from './components/FileList';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function App() {
  const [submittingForm, setSubmittingForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [filesToAdd, setFilesToAdd] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    setErrorMessage('');
  }, [name, email, filesToAdd.length]);

  useEffect(() => {
    if (selectedFiles.length) {
      setFilesToAdd([ ...filesToAdd, ...selectedFiles ]);
    }
  }, [selectedFiles]);

  const addFiles = async (files) => {
    const filesToUpload = [];

    for (let i = 0; i < files.length; i++) {
      filesToUpload.push(files[i]);
    }

    setSelectedFiles(filesToUpload);
  };

  const submit = async (e) => {
    e.preventDefault();

    if (submittingForm) {
      return;
    }

    const emailToSubmit = email.toLowerCase();
    const isEmail = emailRegex.test(emailToSubmit);

    if (!name) {
      setErrorMessage('Name is required. Please enter your name.');
      return;
    }

    if (!isEmail) {
      setErrorMessage('Email is required. Please enter a valid email.');
      return;
    }

    if (!filesToAdd.length) {
      setErrorMessage('Files are required. Please add files to continue.');
      return;
    }

    setSubmittingForm(true);

    try {
      // <iframe src="https://staging-action-verification.firebaseapp.com/" height="100%" width="100%" title="Verification Form" frameborder="0"></iframe>
      const storage = getStorage();
      const urls = [];

      for (let i = 0; i < filesToAdd.length; i++) {
        const file = filesToAdd[i];
        const currentMilliseconds = Date.now();
        const storageRef = ref(storage, `action-verification/${currentMilliseconds}-${file.name}`);

        await uploadBytes(storageRef, file);

        const url = await getDownloadURL(storageRef);

        urls.push(url);
      }

      const db = getFirestore();

      await addDoc(collection(db, 'action-verification'), {
        email: emailToSubmit,
        name,
        urls,
        approved: false,
        action: '', // example: gift-card-sent
        created: Date.now(),
        updated: Date.now(),
        lastUpdatedBy: emailToSubmit,
      });

      setSubmittingForm(false);
      setFormSubmitted(true);
    } catch (e) {
      setSubmittingForm(false);
      setErrorMessage('An error occurred, please try again.');
    }
  };

  return (
    <div className="App">
      {formSubmitted ?
        <div className="success-container">
          <h1>Thank you!</h1>
          <p>Your info has been submitted and is being reviewed by our team.</p>
        </div> :
        <form onSubmit={submit}>
          <div>
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Enter your name"
            />
          </div>

          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="someone@example.com"
            />
          </div>

          <div>
            <label>Files:</label>
            <StyledDropzone onDrop={addFiles} />
            {!filesToAdd.length ? null :
              <FileList
                files={filesToAdd}
                removeFile={(index) => {
                  const filesCopy = [ ...filesToAdd ];

                  filesCopy.splice(index, 1);

                  setFilesToAdd(filesCopy);
                }}
              />
            }
          </div>

          {!errorMessage ? null :
            <div className="error-message-container">
              {errorMessage}
            </div>
          }

          <button
            type="submit"
            className="submit-button"
          >
            Submit
          </button>
        </form>
      }
    </div>
  );
}

export default App;
